import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Seo from "../components/seo"
import Headings from "../components/headings"
import Hero from "../components/hero"
import NewsGrid from "../components/news-grid"
import CtaSlider from "../components/cta-slider"
import PageAdvert from "../components/page-advert"

const NewsPage = pageContext => {
  //console.log("News Page pageContext: ", pageContext)

  const { allStrapiArticle, strapiNews } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { order: DESC, fields: strapi_id }) {
        nodes {
          ...StrapiArticles
        }
      }
      strapiNews {
        hero {
          ...StrapiHero
        }
        headings {
          title
          description
        }
        pageAd {
          ...PageAdverts
        }
        content {
          data {
            content
          }
        }
        seo {
          ...StrapiSeo
          metaSocial {
            socialNetwork
            title
            description
          }
          structuredData {
            _type
            name
            url
            contactPoint {
              _type
              availableLanguage
              contactType
              telephone
            }
            sameAs
          }
        }
        callToAction {
          ...StrapiCallToAction
        }
        newsAdvertOverlayContent {
          news_advert_overlays {
            title
            heading
            description
            buttonName
            link
            image {
              alternativeText
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(
                    formats: AUTO
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    aspectRatio: 1.1
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  //console.log("strapiNews: ", strapiNews)

  const {
    hero,
    headings,
    seo,
    callToAction,
    newsAdvertOverlayContent,
    pageAd
  } = strapiNews

  const structuredData = seo.structuredData
  const imageUrl = `${seo.metaImage.localFile.url}`
  const articles = allStrapiArticle?.nodes 
  const callToActionSections = callToAction.call_to_action_sections
  const newsAdverts = newsAdvertOverlayContent.news_advert_overlays

  const customerCentricContent = pageAd.page_adverts[0]
  const engageShareAndStayConnected = pageAd.page_adverts[1]
  
  //console.log("Strapi Article: ", articles)  
  //console.log("Strapi News featuredArticle: ", featuredArticle)  
  //console.log("strapiNews callToAction: ", callToAction)
  //console.log("strapiNews callToActionSections: ", callToActionSections)
  //console.log("strapiNews newsAdverts: ", newsAdverts)

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "website",
            structuredData,
          })}
        </script>
      </Helmet>

      <Seo
        title={seo.metaTitle}
        description={seo.metaDescription}
        image={imageUrl}
        post={false}
        metaSocial={seo.metaSocial}
        metaRobots={seo.metaRobots}
      />

      <Hero hero={hero} />

      <section className="uk-section uk-section-default uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
        <Headings
          title={headings.title}
          description={headings.description}
        />
          <PageAdvert advert={customerCentricContent} background="uk-background-default" />        
          <NewsGrid articles={articles} newsAdverts={newsAdverts} />
          <PageAdvert advert={engageShareAndStayConnected} background="uk-background-default" />         
        </div>
      </section>

      <section className="uk-section uk-section-muted uk-padding-remove">
        <div className="uk-container uk-margin-medium-bottom">
            <Headings
              title={callToAction.title}
              description={callToAction.description}
            />
          <div className="uk-container uk-margin">
            <CtaSlider callToActionSections={callToActionSections} />
          </div>
        </div>
      </section>
    </>
  )
}

export default NewsPage
